import Decimal from '@st/decimal'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import useCryptoFormatter from '@st/i18n/composables/useCryptoFormatter'
import useNumberFormatter from '@st/i18n/composables/useNumberFormatter'
import useCurrencyFormatter from '@st/i18n/composables/useCurrencyFormatter'
import { isTruthy } from '@st/utils'
import type {
  ExtendedDepositBonus,
  CashDepositAwardSettings,
  FreebetDepositAwardSettings,
  ReloadDepositAwardSettings,
  FreespinDepositAwardSettings,
} from '../types'

interface BonusShortInfo {
  icon: string | null
  amount: string
  amountWithCurrency: string
  text: string
}
interface UseDepositBonusReturn {
  rollingMultiplier: ComputedRef<string | 0>
  maxDepositAmount: ComputedRef<{
    amount: string
    bonusCurrency: {
      code: string
      icon: string
    } | null
  } | null>
  minDepositAmount: ComputedRef<string>
  isMultiBonus: ComputedRef<boolean>
  cashDepositBonusSettings: ComputedRef<
    CashDepositAwardSettings | null | undefined
  >
  freebetDepositBonusSettings: ComputedRef<
    FreebetDepositAwardSettings | null | undefined
  >
  freespinDepositBonusSettings: ComputedRef<
    FreespinDepositAwardSettings | null | undefined
  >
  reloadDepositBonusSettings: ComputedRef<
    ReloadDepositAwardSettings | null | undefined
  >
  titleType: ComputedRef<string>
  titleByLevel: ComputedRef<string>
  subTitle: ComputedRef<string>
  filteredMultiBonuses: ComputedRef<BonusShortInfo[]>
  bonusesOutput: ComputedRef<{
    main: BonusShortInfo
    others: BonusShortInfo[]
  }>
  freespinAmountInAppCurrency: ComputedRef<string | null>
  bonusesCurrencies: ComputedRef<{
    default: {
      code: string
      icon: string
    }
    freebet: {
      code: string
      icon: string
    }
    freespin: {
      code: null
      icon: null
    }
    reload: {
      code: string
      icon: string
    }
  }>
  maxDefaultBonusAmountWithCurrency: ComputedRef<string | 0>
  maxFreespinBonusFiatAmount: ComputedRef<string | 0>
  isStepBonus: ComputedRef<boolean>
}

/**
 * хук предоставляющий всю информацию по бонусной программе за депозит
 * и ее наградам
 */
export function useDepositBonus(
  bonus: Ref<ExtendedDepositBonus | null | undefined>,
): UseDepositBonusReturn {
  const { t } = useI18n()
  const currenciesStore = useCurrenciesStore()
  const { getCurrencyById } = currenciesStore
  const { appCurrency } = storeToRefs(currenciesStore)
  const { format: formatCrypto } = useCryptoFormatter()
  const { format: formatCurrency } = useCurrencyFormatter({
    currency: appCurrency.value.code,
  })
  const { format: formatMultiplier } = useNumberFormatter()
  const { format: formatCryptoWithCurrencyCode } = useCryptoFormatter({
    style: 'currency',
  })

  const cashDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.bonusSettings,
  )
  const freebetDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.freebet,
  )
  const freespinDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.freespinProgram,
  )
  const reloadDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.reloadProgram,
  )

  function getBonusCurrencyCode(id: number | undefined) {
    if (!id) return ''
    return getCurrencyById(id)?.code
  }
  function getBonusCurrencyIcon(id: number | undefined) {
    if (!id) return ''
    return getCurrencyById(id)?.icon
  }
  const bonusesCurrencies = computed(() => ({
    default: {
      code: getBonusCurrencyCode(cashDepositBonusSettings.value?.currencyId),
      icon: getBonusCurrencyIcon(cashDepositBonusSettings.value?.currencyId),
    },
    freebet: {
      code: getBonusCurrencyCode(freebetDepositBonusSettings.value?.currencyId),
      icon: getBonusCurrencyIcon(freebetDepositBonusSettings.value?.currencyId),
    },
    freespin: {
      code: null,
      icon: null,
    },
    reload: {
      code: getBonusCurrencyCode(reloadDepositBonusSettings.value?.currencyId),
      icon: getBonusCurrencyIcon(reloadDepositBonusSettings.value?.currencyId),
    },
  }))
  const cashAward = computed(() => {
    if (!cashDepositBonusSettings.value) return null
    const amount = `+${formatMultiplier(
      new Decimal(cashDepositBonusSettings.value.depositMultiplier ?? 0)
        .mul(100)
        .toString(),
    )}%`

    return {
      icon: null,
      amount,
      amountWithCurrency: amount,
      text: '',
    }
  })
  const freespinAward = computed(() => {
    if (!freespinDepositBonusSettings.value) return null
    const amount = `${freespinDepositBonusSettings.value.freespinNumber} FS`
    return {
      icon: null,
      amount,
      amountWithCurrency: amount,
      text: '',
    }
  })
  const freebetAward = computed(() => {
    if (!freebetDepositBonusSettings.value) return null

    return {
      icon: bonusesCurrencies.value.freebet.icon,
      amount: formatCrypto(freebetDepositBonusSettings.value.amount),
      amountWithCurrency: formatCryptoWithCurrencyCode(
        freebetDepositBonusSettings.value.amount,
        bonusesCurrencies.value.freebet.code,
      ),
      text: t('bonuses.freebet'),
    }
  })
  const reloadAward = computed(() => {
    if (!reloadDepositBonusSettings.value) return null

    return {
      icon: bonusesCurrencies.value.reload.icon,
      amount: formatCrypto(reloadDepositBonusSettings.value.amount),
      amountWithCurrency: formatCryptoWithCurrencyCode(
        reloadDepositBonusSettings.value.amount,
        bonusesCurrencies.value.reload.code,
      ),
      text: t('bonuses.reload'),
    }
  })
  const filteredMultiBonuses = computed(() =>
    [
      cashAward.value,
      freespinAward.value,
      freebetAward.value,
      reloadAward.value,
    ].filter(isTruthy),
  )
  const isMultiBonus = computed(() => filteredMultiBonuses.value.length > 1)
  const isStepBonus = computed(() => !!bonus.value?.steps?.length)
  const bonusesOutput = computed(() => ({
    main: filteredMultiBonuses.value[0],
    others: isMultiBonus.value ? filteredMultiBonuses.value.slice(1) : [],
  }))

  const titleType = computed(() => {
    if (isMultiBonus.value) return t('bonuses.bonus')

    if (freebetDepositBonusSettings.value) return t('bonuses.freebet')
    if (freespinDepositBonusSettings.value) return t('bonuses.freespins')
    if (reloadDepositBonusSettings.value) return t('bonuses.reload')

    return t('bonuses.bonus')
  })
  const subTitle = computed(() => {
    if (isMultiBonus.value) return ''

    if (freebetDepositBonusSettings.value) return t('bonuses.freebetSubtitle')
    if (freespinDepositBonusSettings.value) return t('bonuses.freespinSubtitle')
    if (reloadDepositBonusSettings.value)
      return t(
        'bonuses.reloadSubtitle',
        reloadDepositBonusSettings.value.bonusRepeatIntervalInHours,
      )

    return t('bonuses.defaultSubtitle')
  })
  const titleByLevel = computed(() => {
    const level = bonus.value?.level
    if (!level) return t('bonuses.depositNTitle', { type: titleType.value })

    switch (level) {
      case 1:
        return t('bonuses.depositNTitle', {
          type: titleType.value,
          level: t('bonuses.levels.first'),
        })
      case 2:
        return t('bonuses.depositNTitle', {
          type: titleType.value,
          level: t('bonuses.levels.second'),
        })
      case 3:
        return t('bonuses.depositNTitle', {
          type: titleType.value,
          level: t('bonuses.levels.third'),
        })
      default:
        return t('bonuses.depositNTitle', { type: titleType.value }, level)
    }
  })

  const { format: formatFreespinFiat } = useCurrencyFormatter({
    currency: computed(() => {
      if (!freespinDepositBonusSettings.value?.freespinCurrencyId) return 'USD'

      return (
        getCurrencyById(freespinDepositBonusSettings.value.freespinCurrencyId)
          .code ?? 'USD'
      )
    }),
  })
  const maxFreespinBonusFiatAmount = computed(() =>
    freespinDepositBonusSettings.value?.maxRewardAmount
      ? formatFreespinFiat(freespinDepositBonusSettings.value.maxRewardAmount)
      : 0,
  )
  const freespinAmountInAppCurrency = computed(() => {
    if (!freespinDepositBonusSettings.value?.freespinBetAmount) return null

    return formatFreespinFiat(
      freespinDepositBonusSettings.value.freespinBetAmount,
    )
  })
  const maxDefaultBonusAmountWithCurrency = computed(() =>
    cashDepositBonusSettings.value?.maxBonusAmountInBonusCurrency
      ? formatCryptoWithCurrencyCode(
          cashDepositBonusSettings.value.maxBonusAmountInBonusCurrency,
          bonusesCurrencies.value.default.code,
        )
      : 0,
  )
  const maxCashBonusAmount = computed(() =>
    cashDepositBonusSettings.value?.maxBonusAmountInBonusCurrency
      ? formatCrypto(
          cashDepositBonusSettings.value.maxBonusAmountInBonusCurrency,
        )
      : 0,
  )
  const maxDepositAmount = computed(() => {
    if (maxCashBonusAmount.value) {
      return {
        amount: maxCashBonusAmount.value,
        bonusCurrency: bonusesCurrencies.value.default,
      }
    }

    if (maxFreespinBonusFiatAmount.value) {
      return {
        amount: maxFreespinBonusFiatAmount.value,
        bonusCurrency: null,
      }
    }

    return null
  })
  const rollingMultiplier = computed(() => {
    const wager =
      cashDepositBonusSettings.value?.rollingMultiplier ??
      freespinDepositBonusSettings.value?.rollingMultiplier ??
      freebetDepositBonusSettings.value?.freebetRollingSettings
        .rollingMultiplier

    return wager ? formatMultiplier(wager) : 0
  })
  const minDepositAmount = computed(() => {
    const minFromAmount =
      bonus.value?.minFromAmount ??
      bonus.value?.minDepositAmountInAppCurrency ??
      0
    return formatCurrency(minFromAmount)
  })

  return {
    rollingMultiplier,
    maxDepositAmount,
    minDepositAmount,
    isMultiBonus,
    cashDepositBonusSettings,
    freebetDepositBonusSettings,
    freespinDepositBonusSettings,
    reloadDepositBonusSettings,
    titleType,
    titleByLevel,
    subTitle,
    filteredMultiBonuses,
    bonusesOutput,
    freespinAmountInAppCurrency,
    bonusesCurrencies,
    maxDefaultBonusAmountWithCurrency,
    maxFreespinBonusFiatAmount,
    isStepBonus,
  }
}
